import { render, staticRenderFns } from "./SifremiUnuttumPass.vue?vue&type=template&id=242c9e18&scoped=true&"
import script from "./SifremiUnuttumPass.vue?vue&type=script&lang=js&"
export * from "./SifremiUnuttumPass.vue?vue&type=script&lang=js&"
import style0 from "./SifremiUnuttumPass.vue?vue&type=style&index=0&id=242c9e18&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "242c9e18",
  null
  
)

export default component.exports